import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCards, Navigation, Pagination, Autoplay } from "swiper"
import "swiper/swiper-bundle.min.css"
import generalStyles from "../../styles/General.module.css"
import Button from "../general/Button"
import { BsGithub } from "react-icons/bs"
import { ImLink } from "react-icons/im"
import { getDownloads } from "../../utils/selectService"
import Circle from "./Circle"

function WorkBlock() {
  const { t } = useTranslation()
  const works = useSelector((state) => state.data.works)

  return (
    <div className={generalStyles.generalBlock}>
      <div
        id="works"
        className={`${generalStyles.blockHeaderText} ${generalStyles.w100}`}
      >
        <Circle
          className="circleWork"
          width={615}
          height={615}
          radius={615}
          opacity={0.4}
          color={"rgba(51, 71, 242, 0.50)"}
          blur={150}
        />
        {t("headers.works").toUpperCase()}
      </div>
      <div className={`${generalStyles.w100}`}>
        <Swiper
          modules={[Navigation, Pagination, EffectCards, Autoplay]}
          spaceBetween={50}
          cardsEffect={{
            perSlideOffset: 8,
          }}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true,
          }}
          pagination={{ clickable: true }}
          effect="cards"
        >
          {works.map((work, id) => {
            return (
              <SwiperSlide key={id}>
                <div className={`${generalStyles.customSlide}`}>
                  <div
                    className={`${generalStyles.slideRow} ${generalStyles.slideTitle}`}
                  >
                    {work.header}
                  </div>
                  <div
                    className={`${generalStyles.slideRow} ${generalStyles.slideDescription}`}
                  >
                    {work.description}
                  </div>
                  <div
                    className={`${generalStyles.slideRow} ${generalStyles.slideButtons}`}
                  >
                    {work.gitRepo ? (
                      <Button icon={<BsGithub />} link={work.gitRepo}>
                        GitHub
                      </Button>
                    ) : (
                      <></>
                    )}
                    {work.destinationLink ? (
                      <Button icon={<ImLink />} link={work.destinationLink}>
                        Link
                      </Button>
                    ) : (
                      <></>
                    )}
                    {work.downloads.length > 0 ? (
                      work.downloads.map((download, idx) => {
                        return (
                          <Button
                            key={idx}
                            icon={getDownloads(download.os)}
                            link={download.ftp}
                          >
                            Download
                          </Button>
                        )
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default WorkBlock
