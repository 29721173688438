import React from "react"
import generalStyles from "../../styles/General.module.css"
import Contacts from "../general/Contacts"
import CustomMenu from "../general/CustomMenu"

function Footer() {
  return (
    <div className={`${generalStyles.fullWidthBlock} ${generalStyles.footer}`}>
      <CustomMenu bgcNone mbNone />
      <Contacts />
      <div className={`${generalStyles.copyRight} ${generalStyles.mobileNone}`}>
        YEVHENII TOMBERG
      </div>
    </div>
  )
}

export default Footer
