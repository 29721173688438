import React from "react"
import generalStyles from "../../styles/General.module.css"

function Button({ icon = null, link, children }) {
  return (
    <div
      className={generalStyles.customButton}
      onClick={() => {
        window.open(link)
      }}
    >
      {icon ? (
        <div className={`${generalStyles.buttonIcon}`}>{icon}</div>
      ) : (
        <></>
      )}
      <div>{children}</div>
    </div>
  )
}

export default Button
