import React from "react"
import { useSelector } from "react-redux"
import generalStyles from "../../styles/General.module.css"
import { getContact } from "../../utils/selectService"
import { v4 } from "uuid"

function Contacts({ pcNone = false }) {
  const contacts = useSelector((state) => state.data.contacts)

  return (
    <div
      className={`${generalStyles.flex} ${generalStyles.contacts} ${
        pcNone ? generalStyles.pcNone : ""
      }`}
    >
      {contacts.map((contact) => {
        return (
          <a
            key={v4()}
            className={`${generalStyles.contact}`}
            href={contact.link}
            target="_blank"
            rel="noreferrer"
          >
            {getContact(contact.portal.toLowerCase())}
          </a>
        )
      })}
    </div>
  )
}

export default Contacts
