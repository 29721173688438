import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useTranslation } from "react-i18next"
import Circle from "./Circle"

function Description() {
  const { t } = useTranslation()

  return (
    <div className={`${generalStyles.descriptionBlock}`} id="aboutMe">
      <Circle
        className="circleAboutMe1"
        width={300}
        height={300}
        radius={300}
        opacity={0.2}
        color={"#3347F2"}
        blur={175}
      />
      <Circle
        className="circleAboutMe2"
        width={600}
        height={600}
        radius={600}
        opacity={0.2}
        color={"#3347F2"}
        blur={250}
      />
      <div>{t("description.text")}</div>
    </div>
  )
}

export default Description
