import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import EducationItem from "./EducationItem"

function EducationBlock() {
  const { t } = useTranslation()
  const education = useSelector((state) => state.data.education)

  return (
    <div className={`${generalStyles.generalBlock}`}>
      <div
        id="education"
        className={`${generalStyles.blockHeaderText} ${generalStyles.w100}`}
      >
        {t("menu.education")}
      </div>
      <div className={`${generalStyles.educationItemsContainer}`}>
        {education.map((edu, idx) => {
          return <EducationItem key={idx} education={edu} />
        })}
      </div>
    </div>
  )
}

export default EducationBlock
