import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "eng",
  resources: {
    eng: {
      translation: {
        menu: {
          aboutMe: "ABOUT ME",
          skills: "SKILLS",
          works: "WORKS",
          experience: "EXPERIENCE",
          education: "EDUCATION",
          contact: "CONTACT",
        },
        description: {
          text: "I am communicative, attentive and responsible software engineer with over 5 years of experience in information systems development. My technical expertise includes Java with the Spring Framework, React, Python, Docker, Git, Jenkins, Selenium and other technologies. I am dedicated to creating new software and improving/maintaining existing software, which requires me to be detail-oriented and committed to delivering high-quality solutions that meet user needs. My communication skills allow me to work collaboratively with other team members and stakeholders to understand requirements and ensure that software is developed to meet business needs. I am also an adaptable learner who stays up-to-date with new technologies and tools to continuously improve your skills and knowledge. Overall, my experience and technical expertise make me a valuable asset to any software development team",
        },
        headers: {
          skills: "What you need to know about my professional skills",
          works: "Examples of my work",
          experienceInCompanies: "Experience in companies",
          fillForm: "fill out the form to contact me",
          faq: "FAQ",
        },
        utils: {
          send: "SEND",
        },
        form: {
          name: "Name",
          email: "Email",
          message: "Message",
        },
      },
    },
    est: {
      translation: {
        menu: {
          aboutMe: "ABOUT ME",
          skills: "SKILLS",
          works: "WORKS",
          experience: "EXPERIENCE",
          education: "EDUCATION",
          contact: "CONTACT",
        },
        description: {
          text: "I am communicative, attentive and responsible software engineer with over 5 years of experience in information systems development. My technical expertise includes Java with the Spring Framework, React, Python, Docker, Git, Jenkins, Selenium and other technologies. I am dedicated to creating new software and improving/maintaining existing software, which requires me to be detail-oriented and committed to delivering high-quality solutions that meet user needs. My communication skills allow me to work collaboratively with other team members and stakeholders to understand requirements and ensure that software is developed to meet business needs. I am also an adaptable learner who stays up-to-date with new technologies and tools to continuously improve your skills and knowledge. Overall, my experience and technical expertise make me a valuable asset to any software development team",
        },
        headers: {
          skills: "What you need to know about my professional skills",
          works: "Examples of my work",
          experienceInCompanies: "Experience in companies",
          fillForm: "fill out the form to contact me",
          faq: "FAQ",
        },
        utils: {
          send: "SEND",
        },
        form: {
          name: "Name",
          email: "Email",
          message: "Message",
        },
      },
    },
    rus: {
      translation: {
        menu: {
          aboutMe: "ABOUT ME",
          skills: "SKILLS",
          works: "WORKS",
          experience: "EXPERIENCE",
          education: "EDUCATION",
          contact: "CONTACT",
        },
        description: {
          text: "I am communicative, attentive and responsible software engineer with over 5 years of experience in information systems development. My technical expertise includes Java with the Spring Framework, React, Python, Docker, Git, Jenkins, Selenium and other technologies. I am dedicated to creating new software and improving/maintaining existing software, which requires me to be detail-oriented and committed to delivering high-quality solutions that meet user needs. My communication skills allow me to work collaboratively with other team members and stakeholders to understand requirements and ensure that software is developed to meet business needs. I am also an adaptable learner who stays up-to-date with new technologies and tools to continuously improve your skills and knowledge. Overall, my experience and technical expertise make me a valuable asset to any software development team",
        },
        headers: {
          skills: "What you need to know about my professional skills",
          works: "Examples of my work",
          experienceInCompanies: "Experience in companies",
          fillForm: "fill out the form to contact me",
          faq: "FAQ",
        },
        utils: {
          send: "SEND",
        },
        form: {
          name: "Name",
          email: "Email",
          message: "Message",
        },
      },
    },
    ukr: {
      translation: {
        menu: {
          aboutMe: "ABOUT ME",
          skills: "SKILLS",
          works: "WORKS",
          experience: "EXPERIENCE",
          education: "EDUCATION",
          contact: "CONTACT",
        },
        description: {
          text: "I am communicative, attentive and responsible software engineer with over 5 years of experience in information systems development. My technical expertise includes Java with the Spring Framework, React, Python, Docker, Git, Jenkins, Selenium and other technologies. I am dedicated to creating new software and improving/maintaining existing software, which requires me to be detail-oriented and committed to delivering high-quality solutions that meet user needs. My communication skills allow me to work collaboratively with other team members and stakeholders to understand requirements and ensure that software is developed to meet business needs. I am also an adaptable learner who stays up-to-date with new technologies and tools to continuously improve your skills and knowledge. Overall, my experience and technical expertise make me a valuable asset to any software development team",
        },
        headers: {
          skills: "What you need to know about my professional skills",
          works: "Examples of my work",
          experienceInCompanies: "Experience in companies",
          fillForm: "fill out the form to contact me",
          faq: "FAQ",
        },
        utils: {
          send: "SEND",
        },
        form: {
          name: "Name",
          email: "Email",
          message: "Message",
        },
      },
    },
  },
})

export default i18n
