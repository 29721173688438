import React from "react"
import generalStyles from "../../styles/General.module.css"

function Circle({
  width,
  height,
  opacity,
  color,
  blur,
  radius,
  className,
  mbNone = false,
  pcNone = false,
}) {
  return (
    <div
      className={`${className} ${mbNone ? generalStyles.mobileNone : ""} ${
        pcNone ? generalStyles.pcNone : ""
      }`}
      style={{
        "width": `${width}px`,
        "height": `${height}px`,
        "opacity": `${opacity}`,
        "backgroundColor": `${color}`,
        "filter": `blur(${blur}px)`,
        "borderRadius": `${radius}px`,
        "transform": "translate3d(0,0,0)",
      }}
    ></div>
  )
}

export default Circle
