import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useSelector } from "react-redux"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import Circle from "./Circle"

function SkillsBlock() {
  const skills = useSelector((state) => state.data.skills)

  return (
    <div
      className={`${generalStyles.generalBlock} ${generalStyles.skillsBlock}`}
    >
      <Circle
        className="circleSkillsLeft"
        width={900}
        height={900}
        radius={900}
        opacity={0.4}
        color={"#3347F2"}
        blur={150}
      />
      <Circle
        className="circleSkillsRight"
        width={900}
        height={900}
        radius={900}
        opacity={0.4}
        color={"#3347F2"}
        blur={150}
      />
      {skills.map((skill, idx) => {
        return (
          <CircularProgressbar
            className={`${generalStyles.skillItem}`}
            key={idx}
            value={skill.percent}
            text={skill.name}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathTransitionDuration: 0.5,
              pathColor: `#fff`,
              textColor: "#fff",
              trailColor: "#111527",
            })}
          />
        )
      })}
    </div>
  )
}

export default SkillsBlock
