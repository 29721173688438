import React from "react"
import generalStyles from "../../styles/General.module.css"
import { RingLoader } from "react-spinners"
import { useSelector } from "react-redux"

function Loading() {
  const loading = useSelector((state) => state.data.loading)

  return (
    <div
      className={`${generalStyles.loading} ${
        loading ? "" : generalStyles.hiddenLoading
      }`}
    >
      <RingLoader />
    </div>
  )
}

export default Loading
