import React, { useState } from "react"
import generalStyles from "../../styles/General.module.css"
import {
  PiArrowBendRightDownBold,
  PiArrowBendRightUpBold,
} from "react-icons/pi"

function Collapse({ question, id }) {
  const [open, setOpen] = useState(false)

  const growDiv = () => {
    var growDiv = document.getElementById(`question${id}`)
    if (growDiv.clientHeight) {
      growDiv.style.height = 0
      growDiv.style.margin = 0
    } else {
      var wrapper = document.querySelector(`#measuringWrapper${id}`)
      growDiv.style.height = wrapper.clientHeight + "px"
      growDiv.style.margin = "50px 0"
    }
  }

  const toggle = () => {
    growDiv()
    setOpen(!open)
  }

  return (
    <>
      <div className={`${generalStyles.collapseQuestion}`} onClick={toggle}>
        <div>{question.question}</div>
        <div>
          <PiArrowBendRightDownBold
            className={
              open ? generalStyles.openedArrow : generalStyles.closedArrow
            }
          />
        </div>
      </div>
      <div className={`${generalStyles.collapseAnswer}`} id={`question${id}`}>
        <div id={`measuringWrapper${id}`}>{question.answer}</div>
      </div>
    </>
  )
}

export default Collapse
