import React from "react"
import generalStyles from "../../styles/General.module.css"

function EducationItem({ education }) {
  return (
    <div className={`${generalStyles.educationItem}`}>
      <div
        className={`${generalStyles.educationLine} ${generalStyles.w100} ${generalStyles.textColorBlue}`}
      >
        {`${new Date(education.dateFrom).getFullYear()} - ${
          education.active
            ? "Present"
            : new Date(education.dateTo).getFullYear()
        }`}
      </div>
      <div className={`${generalStyles.educationLine} ${generalStyles.w100}`}>
        {education.name}
      </div>
      <div
        className={`${generalStyles.educationLine} ${generalStyles.w100} ${generalStyles.flex}`}
      >
        <div className={`${generalStyles.w100}`}>Specialization</div>
        <div className={`${generalStyles.textColorBlue}`}>
          {education.specialization}
        </div>
      </div>
    </div>
  )
}

export default EducationItem
