import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import generalStyles from "../../styles/General.module.css"
import Collapse from "../general/Collapse"
import { v4 as uuidv4 } from "uuid"

function FAQBlock() {
  const { t } = useTranslation()
  const questions = useSelector((state) => state.data.questions)

  return (
    <div className={`${generalStyles.generalBlock} ${generalStyles.w100}`}>
      <div className={`${generalStyles.blockHeaderText} ${generalStyles.w100}`}>
        {t("headers.faq").toUpperCase()}
      </div>
      <div className={`${generalStyles.w100}`}>
        <hr />
        {questions.map((question, idx) => {
          return (
            <Fragment key={uuidv4()}>
              <div className={`${generalStyles.question}`}>
                <Collapse question={question} id={idx} />
              </div>
              <hr />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default FAQBlock
