import { FaLinux, FaInstagram, FaFacebookF, FaLinkedin } from "react-icons/fa"
import { SiMacos } from "react-icons/si"
import { BsGithub, BsWindows } from "react-icons/bs"
import { MdEmail } from "react-icons/md"

export const getDownloads = (os) => {
  if (os) {
    switch (os.toLowerCase()) {
      case "windows":
        return <BsWindows />
      case "linux":
        return <FaLinux />
      case "mac_os":
        return <SiMacos />
      default:
        return null
    }
  } else {
    return <BsWindows />
  }
}

export const getContact = (portal) => {
  if (portal) {
    switch (portal) {
      case "facebook":
        return <FaFacebookF />
      case "instagram":
        return <FaInstagram />
      case "linkedin":
        return <FaLinkedin />
      case "github":
        return <BsGithub />
      case "email":
        return <MdEmail />
      default:
        return null
    }
  } else {
    return null
  }
}
