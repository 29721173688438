import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import ExperienceItem from "./ExperienceItem"
import Circle from "./Circle"

function ExperienceBlock() {
  const { t } = useTranslation()
  const experiences = useSelector((state) => state.data.experience)

  return (
    <div className={`${generalStyles.generalBlock}`}>
      <div
        className={`${generalStyles.blockHeaderText} ${generalStyles.w100}`}
        id="experience"
      >
        {t("headers.experienceInCompanies").toUpperCase()}
      </div>
      <div className={`${generalStyles.flex} ${generalStyles.w100}`}>
        <Circle
          className="circleExperienceLeft"
          width={900}
          height={900}
          radius={900}
          opacity={0.4}
          color={"#3347F2"}
          blur={150}
        />
        <Circle
          className="circleExperienceRight"
          width={900}
          height={900}
          radius={900}
          opacity={0.4}
          color={"#3347F2"}
          blur={150}
        />
        {experiences.map((experience, idx) => {
          return <ExperienceItem experience={experience} key={idx} />
        })}
      </div>
    </div>
  )
}

export default ExperienceBlock
