import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import generalStyles from "../../styles/General.module.css"
import TextareaAutosize from "react-textarea-autosize"
import { displayErrors, isValid, validateForm } from "../../utils/validators"
import axios from "axios"
import Circle from "./Circle"
import { API_HOST } from "../../utils/data"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useDispatch, useSelector } from "react-redux"
import { setDisabled } from "../../redux/services/webService"

function FormBlock() {
  const { t } = useTranslation()
  const toastId = useRef(null)
  const [payload, setPayload] = useState({ name: "", email: "", message: "" })
  const sendButtonDisabled = useSelector(
    (state) => state.web.sendButtonDisabled
  )
  const dispatch = useDispatch()

  const notify = () =>
    (toastId.current = toast("Sending email...", {
      autoClose: false,
      icon: "📬",
    }))
  const success = (message) =>
    toast.update(toastId.current, {
      render: `${message}`,
      icon: "✅",
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
    })
  const decline = (message) =>
    toast.update(toastId.current, {
      render: `${message}`,
      type: toast.TYPE.ERROR,
      icon: "⛔",
      autoClose: 5000,
    })

  const handleChange = (event) => {
    event.target.classList.remove(`${generalStyles.errorInput}`)
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  // prettier-ignore
  const getValidatedObject = () => {
    return validateForm(payload, {
      name: /.+/,
      email:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: /.+/,
    })
  }

  const sendForm = async (event) => {
    event.preventDefault()
    const validatedObject = getValidatedObject()
    if (isValid(validatedObject)) {
      notify()
      dispatch(setDisabled(true))
      const data = await axios
        .post(`${API_HOST}/tomberg-tech/contact`, payload)
        .then((response) => response.data)
        .catch((response) => response.response.data)
      dispatch(setDisabled(false))
      if (data.data.error) {
        decline(data.data.message)
      } else {
        success(data.data.message)
      }
    } else {
      displayErrors(validatedObject)
    }
  }

  return (
    <div className={`${generalStyles.generalBlock} ${generalStyles.w100}`}>
      <div
        className={`${generalStyles.blockHeaderText} ${generalStyles.w100}`}
        id="contact"
      >
        {t("headers.fillForm").toUpperCase()}
      </div>
      <form className={`${generalStyles.form}`} id="contactForm">
        <Circle
          className="circleForm"
          width={523}
          height={523}
          radius={523}
          opacity={0.4}
          color={"rgba(51, 71, 242, 0.40)"}
          blur={250}
        />
        <div className={`${generalStyles.formRow}`}>
          <input
            onChange={handleChange}
            className={generalStyles.formInput}
            name="name"
            type="text"
            id="name"
            maxLength={50}
            placeholder={t("form.name")}
          />
        </div>
        <div className={`${generalStyles.formRow}`}>
          <input
            onChange={handleChange}
            className={generalStyles.formInput}
            id="email"
            name="email"
            type="email"
            maxLength={320}
            placeholder={t("form.email")}
          />
        </div>
        <div className={`${generalStyles.formRow}`}>
          <TextareaAutosize
            onChange={handleChange}
            form="contactForm"
            className={generalStyles.formInput}
            id="message"
            name="message"
            type="text"
            maxLength={400}
            placeholder={t("form.message")}
          />
          <div className={`${generalStyles.disclaimer}`}>
            By clicking on the "SEND" button you agree to the processing of
            personal data in accordance with our privacy policy.
          </div>
        </div>
        <div className={`${generalStyles.formRow}`}>
          <button
            disabled={sendButtonDisabled}
            type="submit"
            className={`${generalStyles.customButton}`}
            onClick={sendForm}
          >
            {t("utils.send")}
          </button>
        </div>
        <ToastContainer />
      </form>
    </div>
  )
}

export default FormBlock
