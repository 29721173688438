import { createSlice } from "@reduxjs/toolkit"

const webSlice = createSlice({
  name: "web",
  initialState: {
    opened: false,
    sendButtonDisabled: false,
  },
  reducers: {
    setOpened: (state) => {
      state.opened = !state.opened
    },
    setDisabled: (state, action) => {
      state.sendButtonDisabled = action.payload
    },
  },
})
export const { setOpened, setDisabled } = webSlice.actions
export default webSlice.reducer
