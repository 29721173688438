import React from "react"
import generalStyles from "../../styles/General.module.css"
import Circle from "./Circle"
import CustomMenu from "../general/CustomMenu"
import Hamburger from "hamburger-react"
import { useDispatch, useSelector } from "react-redux"
import { setOpened } from "../../redux/services/webService"
import Contacts from "../general/Contacts"

function Header() {
  const opened = useSelector((state) => state.web.opened)
  const loading = useSelector((state) => state.data.loading)
  const dispatch = useDispatch()

  return (
    <div
      className={`${generalStyles.collapse} ${
        opened ? generalStyles.fullHeight : ""
      } ${loading ? "" : generalStyles.loaded}`}
    >
      <div className={`${generalStyles.burger}`}>
        <Hamburger
          onToggle={() => {
            dispatch(setOpened(!opened))
          }}
          toggled={opened}
        />
      </div>
      <div
        className={`${generalStyles.header} ${
          opened ? generalStyles.opened : generalStyles.closed
        }`}
      >
        <Circle
          className="circleHeader"
          width={523}
          height={523}
          radius={523}
          opacity={0.4}
          color={"#3347F2"}
          blur={150}
          mbNone
        />
        <CustomMenu />
        <Contacts pcNone />
      </div>
    </div>
  )
}

export default Header
