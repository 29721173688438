import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useDispatch, useSelector } from "react-redux"
import { setOpened } from "../../redux/services/webService"

function MenuButton({ children, id }) {
  const opened = useSelector((state) => state.web.opened)
  const dispatch = useDispatch()
  return (
    <a
      className={generalStyles.menuButton}
      onClick={() => {
        document
          .getElementById(id)
          .scrollIntoView({ behavior: "smooth", block: "start" })
        if (opened) {
          dispatch(setOpened(!opened))
        }
      }}
    >
      {children}
    </a>
  )
}

export default MenuButton
