import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API_HOST } from "../../utils/data"

export const fetchData = createAsyncThunk(
  "tomberg-tech/data/contacts",
  async () => {
    const response = await axios.get(`${API_HOST}/tomberg-tech/data`)
    return response.data
  }
)

const dataSlice = createSlice({
  name: "data",
  initialState: {
    loading: true,
    contacts: [],
    questions: [],
    education: [],
    experience: [],
    skills: [],
    works: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.contacts.length = 0
        state.experience.length = 0
        state.questions.length = 0
        state.skills.length = 0
        state.works.length = 0
        state.education.length = 0

        state.contacts.push(...action.payload.contacts)
        state.experience.push(...action.payload.experiences)
        state.questions.push(...action.payload.questions)
        state.skills.push(...action.payload.skills)
        state.works.push(...action.payload.works)
        state.education.push(...action.payload.educations)
        state.loading = false
      })
  },
})
export default dataSlice.reducer
