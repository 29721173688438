import React from "react"
import generalStyles from "../../styles/General.module.css"
import { useTranslation } from "react-i18next"

function SkillsLine() {
  const { t } = useTranslation()

  return (
    <div
      className={`${generalStyles.fullWidthBlock} ${generalStyles.professionalSkillsLine}`}
      id="skills"
    >
      {t("headers.skills").toUpperCase()}
    </div>
  )
}

export default SkillsLine
