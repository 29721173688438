import React from "react"
import generalStyles from "../../styles/General.module.css"
import MenuButton from "./MenuButton"
import { useTranslation } from "react-i18next"

function CustomMenu({ bgcNone = false, mbNone = false }) {
  const { t } = useTranslation()

  return (
    <div
      className={`${generalStyles.menu} ${
        bgcNone ? generalStyles.bgcNone : ""
      } ${mbNone ? generalStyles.mobileNone : ""}`}
    >
      <MenuButton id={"aboutMe"}>{t("menu.aboutMe")}</MenuButton>
      <MenuButton id={"skills"}>{t("menu.skills")}</MenuButton>
      <MenuButton id={"works"}>{t("menu.works")}</MenuButton>
      <MenuButton id={"experience"}>{t("menu.experience")}</MenuButton>
      <MenuButton id={"education"}>{t("menu.education")}</MenuButton>
      <MenuButton id={"contact"}>{t("menu.contact")}</MenuButton>
    </div>
  )
}

export default CustomMenu
