import React from "react"
import generalStyles from "../../styles/General.module.css"

function ExperienceItem({ experience }) {
  return (
    <div className={generalStyles.experienceItem}>
      <div
        className={`${generalStyles.experienceRow} ${generalStyles.textColorBlue}`}
      >{`${new Date(experience.timeFrom).getFullYear()} - ${
        experience.active
          ? "Present"
          : new Date(experience.timeTo).getFullYear()
      }`}</div>
      <div
        className={`${generalStyles.experienceRow} ${generalStyles.experienceItemPos}`}
      >
        {experience.position}
      </div>
      <div className={generalStyles.experienceRow}>{experience.company}</div>
    </div>
  )
}

export default ExperienceItem
