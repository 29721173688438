import { configureStore } from "@reduxjs/toolkit"
import dataReducer from "./services/dataService"
import webReducer from "./services/webService"

export default configureStore({
  reducer: {
    data: dataReducer,
    web: webReducer,
  },
})
