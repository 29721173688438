import generalStyles from "../styles/General.module.css"

export const validateForm = (payload, patterns) => {
  const validatedObject = {}
  Object.keys(payload).forEach((value) => {
    validatedObject[value] = patterns[value].test(payload[value])
  })
  console.log(validatedObject)
  return validatedObject
}

export const isValid = (validatedForm) => {
  return !Object.values(validatedForm).includes(false)
}

export const displayErrors = (validatedForm) => {
  Object.keys(validatedForm).forEach((key) => {
    if (!validatedForm[key]) {
      document.getElementById(key).classList.add(`${generalStyles.errorInput}`)
    }
  })
}
