import React from "react"
import generalStyles from "../../styles/General.module.css"
import Circle from "./Circle"

function NameBlock() {
  return (
    <>
      <div
        className={`${generalStyles.generalBlock} ${generalStyles.nameBlock}`}
      >
        <div className={`${generalStyles.internalNameBlock}`}>
          <div className={`${generalStyles.fullName}`}>
            <div className={`${generalStyles.lastName}`}>
              <Circle
                className="circleLastName"
                width={207}
                height={207}
                radius={207}
                opacity={0.4}
                color={"#3347F2"}
                blur={100}
                pcNone
              />
              TOMBERG
            </div>
            <div className={`${generalStyles.firstName}`}>YEVHENII</div>
          </div>
          <div className={`${generalStyles.sePosition}`}>
            <Circle
              className="circleName"
              width={439}
              height={146}
              radius={439}
              opacity={0.8}
              color={"#3347F2"}
              blur={100}
            />
            SOFTWARE ENGINEER
          </div>
        </div>
      </div>
    </>
  )
}

export default NameBlock
