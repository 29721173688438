import { useEffect } from "react"
import "./App.css"
import Header from "./components/blocks/Header"
import generalStyles from "./styles/General.module.css"
import WebFont from "webfontloader"
import NameBlock from "./components/blocks/NameBlock"
import Description from "./components/blocks/Description"
import SkillsLine from "./components/blocks/SkillsLine"
import SkillsBlock from "./components/blocks/SkillsBlock"
import EducationBlock from "./components/blocks/EducationBlock"
import WorkBlock from "./components/blocks/WorkBlock"
import ExperienceBlock from "./components/blocks/ExperienceBlock"
import FAQBlock from "./components/blocks/FAQBlock"
import FormBlock from "./components/blocks/FormBlock"
import Footer from "./components/blocks/Footer"
import { useDispatch, useSelector } from "react-redux"
import { fetchData } from "./redux/services/dataService"
import Loading from "./components/general/Loading"

function App() {
  const opened = useSelector((state) => state.web.opened)
  const loading = useSelector((state) => state.data.loading)
  const dispatch = useDispatch()

  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    dispatch(fetchData())
  }, [dispatch])

  if (opened || loading) {
    document.body.classList.add("overflowH")
    document.documentElement.classList.add("overflowH")
  } else {
    document.body.classList.remove("overflowH")
    document.documentElement.classList.remove("overflowH")
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans", "Ubuntu"],
      },
    })
  }, [])

  return (
    <>
      <Loading />
      <div className={`App ${generalStyles.container}`}>
        <Header />
        <NameBlock />
        <Description />
      </div>
      <SkillsLine />
      <div className={`App ${generalStyles.container}`}>
        <SkillsBlock />
        <EducationBlock />
        <WorkBlock />
        <ExperienceBlock />
        <FAQBlock />
        <FormBlock />
      </div>
      <Footer />
    </>
  )
}

export default App
